<template>
  <div style="height=100%">
    <div class="msg">
      <table width="90%" class="table">
        <caption>
          <h2>邀请分成明细</h2>
        </caption>
        <thead>
          <tr>
            <th>用户名</th>
            <th>金额</th>
            <th>时间</th>
          </tr>
        </thead>
        <tr
          v-for="item in list"
          :key="item.id"
          v-clipboard:copy="item.modle"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          <td>
            {{ item.name }}
          </td>
          <td>{{ item.money }}</td>
          <td>{{ item.time }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
export default {
  name: "info",
  data() {
    return {
      list: [],
    };
  },
  methods: {
    //  复制的方法
    onCopy() {
      this.$toast("复制成功");
    },
    onError() {
      this.$toast("复制失败");
    },
  },
  async mounted() {
    let ret = await this.$api.fn_invite_get();
    ret = ret.data.data;
    ret.forEach((element) => {
      let time = "";
      const data = new Date(parseInt(element.time) * 1000 - 3600000 * 8);
      time = data.toLocaleString();
      this.list.push({
        name: element.yonghuming,
        money: element.money,
        time: time,
      });
    });
  },
  created() {
    Dialog.alert({
      message: "记录只保留1天,且只显示最近的80条",
    }).then(() => {
      // on close
    });
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
}
table td,
table th {
  border: 1px solid #cad9ea;
  color: #666;
  height: 12px;
}
table thead th {
  background-color: #cce8eb;
  width: 50px;
}
table tr:nth-child(odd) {
  background: #fff;
}
table tr:nth-child(even) {
  background: #cbf3f3;
}
.msg {
  margin-bottom: 100px;
}
.pag {
  width: 100%;
  position: fixed;
  bottom: 50px;
  left: 0;
  z-index: 99;
}
</style>
